<template>
  <div class="container h-100">
    <div class="row justify-content-center align-items-center h-100">
      <div class="col-md-6 col-sm-12 col-12 ">
        <div class="sign-user_card ">
          <div class="sign-in-page-data">
            <div class="sign-in-from w-100 m-auto">
              <img src="../../../assets/images/login/mail.png" width="80" alt="">
              <h3 class="mt-3 mb-0">Exito !</h3>
              <p class="text-white">El correo ha sido enviado a <a href="/cdn-cgi/l/email-protection" class="__cf_email__"
                  data-cfemail="5f26302a2d3a323e36331f3b30323e3631713c303271">[email&#160;protected]</a> Por favor
                verifica tu correo electronico y has click en el link para resetear tu contraseña.</p>
              <div class="d-inline-block w-100">
                <router-link :to="{ name: 'auth1.sign-in1' }" class="btn btn-hover mt-3">Iniciar Sesión</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConfirmMail1'
}
</script>
